import React from "react";

const GoogleMap = () => {
    return (
        <iframe
            title="this is title"
            className="contact-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.388799144713!2d114.17045571495478!3d22.30113098532317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400ec3b92bf97%3A0x17cd9acf9a02c443!2z576O6bqX6I-v5buj5aC0QeW6pw!5e0!3m2!1szh-TW!2shk!4v1668657163959!5m2!1szh-TW!2shk"
            aria-hidden="false"
        ></iframe>
    );
};

export default GoogleMap;
