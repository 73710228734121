import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import PageTitleContainer from "../containers/global/page-title/index.jsx";
import ServiceContainer from "../containers/service-box/index.jsx";
import TestimonialContainer from "../containers/home/testimonial/index.jsx";
import BrandContainer from "../containers/global/brand/index.jsx";
import PricingContainer from "../containers/global/pricing/index.jsx";
import CallToAction from "../containers/global/call-to-action/index.jsx";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
// import token from "../creds/token";
import ApiCalendar from "react-google-calendar-api";

const ServicePage = () => {
    const [dateValue, setDateValue] = React.useState(new Date());
    const setDateValue2 = (val) => {
        console.log(val);
        setDateValue(val);
        // calendarapi();
        // console.log(token.client_secret);
        if (ApiCalendar.sign) {
            const now3 = new Date();
            const now4 = new Date(now3.getDate() + 10);
            ApiCalendar.listEvents({
                timeMin: now3.toISOString(),
                timeMax: now4.toISOString(),
                showDeleted: true,
                maxResults: 10,
                orderBy: "updated",
            }).then((result) => {
                console.log(result.items);
            });
        }
    };

    const signIO = (val) => {
        if (val === "sign-in") {
            ApiCalendar.handleAuthClick()
                .then(() => {
                    console.log("sign in successful!");
                })
                .catch((e) => {
                    console.error(`sign in failed ${e}`);
                });
        } else if (val === "sign-out") {
            ApiCalendar.handleSignoutClick();
        }
    };

    return (
        <React.Fragment>
            <Layout>
                <SEO title="Belle Femme Beauty - Service" />
                <div className="wrapper">
                    <Header />
                    <div
                        className="main-content site-wrapper-reveal"
                        style={{ textAlign: "center" }}
                    >
                        <img src="img/bfb/services.jpg" alt="Services" />
                        {/*<button onClick={() => signIO("sign-in")}>*/}
                        {/*    Sign in*/}
                        {/*</button>*/}
                        {/*<div style={{ margin: "auto" }}>*/}
                        {/*    <Calendar*/}
                        {/*        onChange={setDateValue2}*/}
                        {/*        value={dateValue}*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ServicePage;
