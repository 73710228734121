import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";
import SocialIcon from "../../social-icon";

const MobileMenu = ({ show, onClose }) => {
    const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (
            parentEl?.classList.contains("menu-expand") ||
            target.classList.contains("menu-expand")
        ) {
            const element = target.classList.contains("icon")
                ? parentEl
                : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
        }
    };
    return (
        <div
            className={`offcanvas offcanvas-mobile-menu ${
                show ? "offcanvas-open" : ""
            }`}
        >
            <div className="inner">
                <div className="border-bottom mb-3 pb-3 text-end">
                    <button className="offcanvas-close" onClick={onClose}>
                        ×
                    </button>
                </div>
                <div className="offcanvas-head mb-3">
                    <div className="header-top-offcanvas">
                        <p>
                            <i className="icofont-google-map"></i>{" "}
                            <span>地址:</span>尖沙咀美麗華廣場A座6樓602室
                        </p>
                    </div>
                </div>
                <nav className="offcanvas-menu">
                    <ul>
                        <li>
                            <NavLink exact to={process.env.PUBLIC_URL + "/"}>
                                <span className="menu-text">主頁</span>
                            </NavLink>
                            {/*<span*/}
                            {/*    className="menu-expand"*/}
                            {/*    onClick={onClickHandler}*/}
                            {/*    aria-hidden="true"*/}
                            {/*></span>*/}
                            {/*<ul className="offcanvas-submenu">*/}
                            {/*    <li>*/}
                            {/*        <NavLink*/}
                            {/*            exact*/}
                            {/*            to={process.env.PUBLIC_URL + "/"}*/}
                            {/*        >*/}
                            {/*            主頁*/}
                            {/*        </NavLink>*/}
                            {/*    </li>*/}
                            {/*</ul>*/}
                        </li>
                        <li>
                            <NavLink to={process.env.PUBLIC_URL + "/service"}>
                                <span className="menu-text">美容服務</span>
                            </NavLink>
                            {/*<span*/}
                            {/*    className="menu-expand"*/}
                            {/*    onClick={onClickHandler}*/}
                            {/*    aria-hidden="true"*/}
                            {/*></span>*/}
                            {/*<ul className="offcanvas-submenu">*/}
                            {/*    <li>*/}
                            {/*        <NavLink*/}
                            {/*            to={process.env.PUBLIC_URL + "/service"}*/}
                            {/*        >*/}
                            {/*            美容服務*/}
                            {/*        </NavLink>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        <NavLink*/}
                            {/*            to={*/}
                            {/*                process.env.PUBLIC_URL +*/}
                            {/*                "/service-detalis"*/}
                            {/*            }*/}
                            {/*        >*/}
                            {/*            美容服務 Details*/}
                            {/*        </NavLink>*/}
                            {/*    </li>*/}
                            {/*</ul>*/}
                        </li>

                        {/*<li>*/}
                        {/*    <NavLink to={process.env.PUBLIC_URL + "/blog"}>*/}
                        {/*        <span className="menu-text">blog</span>*/}
                        {/*    </NavLink>*/}
                        {/*    <span*/}
                        {/*        className="menu-expand"*/}
                        {/*        onClick={onClickHandler}*/}
                        {/*        aria-hidden="true"*/}
                        {/*    ></span>*/}
                        {/*    <ul className="offcanvas-submenu">*/}
                        {/*        <li>*/}
                        {/*            <NavLink*/}
                        {/*                to={process.env.PUBLIC_URL + "/blog"}*/}
                        {/*            >*/}
                        {/*                Blog list*/}
                        {/*            </NavLink>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <NavLink*/}
                        {/*                to={*/}
                        {/*                    process.env.PUBLIC_URL +*/}
                        {/*                    "/blog-details/1"*/}
                        {/*                }*/}
                        {/*            >*/}
                        {/*                Blog details*/}
                        {/*            </NavLink>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</li>*/}

                        {/*<li>*/}
                        {/*    <NavLink to={process.env.PUBLIC_URL + "/about"}>*/}
                        {/*        about*/}
                        {/*    </NavLink>*/}
                        {/*</li>*/}

                        <li>
                            <NavLink to={process.env.PUBLIC_URL + "/contact"}>
                                地址
                            </NavLink>
                        </li>
                    </ul>
                </nav>
                <div className="offcanvas-social my-4">
                    <ul>
                        {/*<li>*/}
                        {/*    <SocialIcon*/}
                        {/*        path="https://twitter.com/"*/}
                        {/*        icon="icofont-twitter"*/}
                        {/*    />*/}
                        {/*</li>*/}
                        <li>
                            <SocialIcon
                                path="https://www.facebook.com/Bellefemmebeautyhk/"
                                icon="icofont-facebook"
                            />
                        </li>
                        <li>
                            <SocialIcon
                                path="https://www.instagram.com/bellefemme__beauty/"
                                icon="icofont-instagram"
                            />
                        </li>
                        <li>
                            <SocialIcon
                                path="https://wa.me/85298319011"
                                icon="icofont-whatsapp"
                            />
                        </li>
                        {/*<li>*/}
                        {/*    <SocialIcon*/}
                        {/*        path="https://rss.com/"*/}
                        {/*        icon="icofont-rss-feed"*/}
                        {/*    />*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <SocialIcon*/}
                        {/*        path="https://www.youtube.com/"*/}
                        {/*        icon="icofont-play-alt-1"*/}
                        {/*    />*/}
                        {/*</li>*/}
                    </ul>
                </div>

                <ul className="media-wrap">
                    <li className="media media-list">
                        <span className="media-icon">
                            <i className="icofont-clock-time"></i>
                        </span>
                        <div className="media-content">
                            <span className="media-sub-heading">營業時間</span>
                            <span className="media-heading">
                                星期一 - 星期日: 12.00 - 23.00{" "}
                            </span>
                        </div>
                    </li>

                    <li className="media media-list">
                        <span className="media-icon">
                            <i className="icofont-ui-call"></i>
                        </span>
                        <div className="media-content">
                            <span className="media-sub-heading">電話</span>
                            <a
                                className="media-heading"
                                href="tel:+852-98319011"
                            >
                                +852-98319011
                            </a>
                        </div>
                    </li>

                    <li className="media media-list">
                        <span className="media-icon">
                            <i className="icofont-envelope"></i>
                        </span>
                        <div className="media-content">
                            <span className="media-sub-heading">電郵</span>
                            <a
                                className="media-heading"
                                href="mailto:info@bellefemme.beauty"
                            >
                                info@bellefemme.beauty
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

MobileMenu.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};

export default MobileMenu;
