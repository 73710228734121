import React from "react";
import { Link } from "react-router-dom";

const ContactAddress = () => {
    return (
        <div className="contact-info-content">
            <div className="info-address">
                <h2 className="title">
                    Belle Femme Beauty <br /> <span>美容養生館</span>
                </h2>
                <p>尖沙咀美麗華廣場A座6樓602室</p>
                <Link to="/">
                    <span>電郵:</span> info@bellefemme.beauty
                </Link>
            </div>
            <div className="brand-office">
                <div className="info-tem style-two">
                    <h6>電話:</h6>
                    <p>+852-98319011</p>
                </div>
                <div className="info-tem mb-0">
                    <h6>營業時間:</h6>
                    <p>星期一 - 星期日: 12.00 - 23.00</p>
                </div>
            </div>
        </div>
    );
};

export default ContactAddress;
